<template>
  <section id="partners" class="s-partners New target-section">
    <div class="container">
      <div class="row">
        <div class="partners-box">
          <h2 class="display-3 subheading">Meet Our Partners</h2>
          <div class="punchout levelone"></div>
          <!--end levelone-->
          <div class="Desktop">
            <a
              href="https://level1productions.com/collections/wells-lamont"
              class="btn btn--primary TopButton"
              v-smooth-scroll="{ duration: 2000 }"
              target="_blank"
              >SHOP NOW</a
            >
            <a
              href="https://www.evo.com/shop?text=well+lamont"
              class="btn btn--primary BottomBtn"
              v-smooth-scroll="{ duration: 2000 }"
              style="margin-left: 30px"
              target="_blank"
              >SHOP NOW</a
            >
          </div>

          <div class="Mobile">
            <div class="Level">
              <img
                src="https://snow.wellslamont.com/wp-content/uploads/2022/01/le.jpg"
                alt="img"
              />
              <a
                href="https://level1productions.com/collections/wells-lamont"
                class="btn btn--primary TopButton"
                v-smooth-scroll="{ duration: 2000 }"
                target="_blank"
                >SHOP NOW</a
              >
            </div>
            <div class="Evo">
              <img
                src="https://snow.wellslamont.com/wp-content/uploads/2022/01/evo.jpg"
                alt="img"
              />
              <a
                href="https://www.evo.com/shop?text=well+lamont"
                class="btn btn--primary BottomBtn"
                v-smooth-scroll="{ duration: 2000 }"
                style="margin-left: 30px"
                target="_blank"
                >SHOP NOW</a
              >
            </div>
          </div>
        </div>
        <!--end partners box-->
      </div>
      <!--end row-->
    </div>
    <!-- end container -->
  </section>

  <!-- end level one partners section -->
</template>
<script>
export default {
  name: "PartnersSection",
};
</script>
