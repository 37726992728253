<template>
  <section id="home" class="s-home target-section background-fixed">
    <div class="home-content">
      <span class="subheading display-3">Redefining Tradition</span>
      <h1>
        Wells Lamont
        <span class="d-none">SNOW</span>
      </h1>
      <div class="punchout snow"></div>
      <div class="home-content__btn-wrap">
        <a href="#gloves" class="btn btn--primary" v-smooth-scroll="{ duration: 2000 }">OUR GLOVES</a>
      </div>
    </div>
    <img
        class="section-bg"
        v-if="$mq === 'xl' | $mq === 'lg'"
        src="https://snow.wellslamont.com/wp-content/uploads/2020/09/2020-wl-home-template-v4.jpg"
      >
    <!-- end home-content -->
  </section>
  <!-- end s-home -->
</template>
<script>
export default {
  name: "HomeSection"
};
</script>

