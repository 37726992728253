<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot></slot>
          </div>
          <button class="modal-default-button" @click="$emit('close')"><font-awesome-icon :icon="['fas', 'times-circle']" ></font-awesome-icon></button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "SimpleModal",
};
</script>
