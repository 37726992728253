var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({ active: true, callback: _vm.triggerAnim, options: {} }),expression:"{ active: true, callback: triggerAnim, options: {} }"}],staticClass:"s-video target-section",attrs:{"id":"snowvideo","data-aos":"fade-up"}},[_c('div',{staticClass:"hero-holder s-video-snow-cover"},[_vm._m(0),_vm._v(" "),_c('mq-layout',{attrs:{"mq":['lg', 'xl']}},[_c('div',{staticClass:"iframe-wrapper"},[_c('vimeo-player',{ref:"bgplayer",attrs:{"video-id":_vm.vimeoBg.videoId,"loop":"true"},on:{"ready":_vm.onReady}})],1)]),_vm._v(" "),_c('mq-layout',{attrs:{"mq":['xs', 'sm', 'md']}},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(
          'https://snow.wellslamont.com/wp-content/uploads/2019/03/new-section-glove.jpg'
        ),expression:"\n          'https://snow.wellslamont.com/wp-content/uploads/2019/03/new-section-glove.jpg'\n        ",arg:"background-image"}],staticClass:"s-video-fallback"})])],1),_vm._v(" "),_c('div',{staticClass:"s-video-vimeo-nav"},[_c('transition-group',{staticClass:"nav justify-content-center nav-fill",attrs:{"name":"staggered-fade","tag":"div","css":false},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter}},_vm._l((_vm.videos),function(video,index){return _c('a',{key:index,staticClass:"nav-item vimeo-link",style:({
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '90%',
          backgroundImage: 'url(' + video.snippet.thumbnails.medium.url + ')'
        }),attrs:{"data-index":index},on:{"click":function($event){return _vm.openModal(video)}}})}),0)],1),_vm._v(" "),(_vm.showModal)?_c('SimpleModal',{staticClass:"vimeo-modal",on:{"close":_vm.closeModal}},[_c('iframe',{attrs:{"width":"560","height":"315","src":'https://www.youtube.com/embed/' + _vm.videoId,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"s-video-vimeo-nav-bg"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"s-video-content"},[_c('div',{staticClass:"punchout snow-team"}),_vm._v(" "),_c('div',{staticClass:"punchout videos"}),_vm._v(" "),_c('a',{staticClass:"btn btn--primary smoothscroll snow-vimeo",attrs:{"href":"https://www.youtube.com/playlist?list=PLGdESsKO1yKafuOzO7s26UvzIkVSKAxnM","target":"_blank"}},[_vm._v("SNOW VIDEO")])])])
}]

export { render, staticRenderFns }