<template>
  <section id="about-hydrahyde" class="s-about-hydrahyde">
    <div class="hydrahyde-box">
      <div class="hydrahyde-box-header text-right">
        <lazy-image
              :lazy-src="'https://snow.wellslamont.com/wp-content/uploads/2019/01/water_icons@2x.png'"
              alt="Hydrahyde Waterproof + Breathable"
              backgroundColor="none"
              class="img-fluid fade-in"
            ></lazy-image>
        <h3 class="h1 display-1 text-right">Water-Resistant
          <br>& Breathable Leather
        </h3>
      </div>
      <span class="subheading display-3 h5 text-left">Cold, Wet Conditions Are No Obstacle</span>
      <p
        class="hydrahyde-desc text-left"
      >Featured in every Wells Lamont SNOW product, HydraHyde® leather is water-resistant and more breathable than standard leather. This tanning process lasts the life of the leather gloves and will not wear off like other water-repellant treatments.</p>
    </div>
  </section>
</template>
<script>
export default {
  name: "HydraHydeSection"
};
</script>

 