<template>
  <section id="wlteam" class="s-team target-section">
    <div class="container">
      <div class="row" data-aos="fade-up">
        <div class="col-xs-12 col-sm-10 offset-sm-1">
          <div class="meet-team-heading-box">
            <h3>
              Meet The Wells Lamont
              <span class="d-none">Team</span>
            </h3>
          </div>
          <!--end heading-box-->
        </div>
        <!--end col-->
      </div>
      <!--end row-->
      <div class="team-members">
        <div v-for="(member, index) in team" :key="index" class="team-member">
          <div class="team-member-box">
            <router-link
              :to="{name:'member', params: { slug: member.slug, memberData: member }}"
              class="team-member-link"
            >
              <lazy-image
                :lazy-src="member.acf.featured_image.sizes.large"
                :height="member.acf.featured_image.sizes.large_height"
                :width="member.acf.featured_image.sizes.large_width"
                class="img-fluid fade-in"
              ></lazy-image>
              <div class="overlay"></div>
              <div class="team-member-info">
                <h4>
                  <span class="light">Team Member</span>
                  <br>
                  {{ member.acf.first_name }} {{ member.acf.last_name }}
                </h4>
              </div>
            </router-link>
            <div v-if="member.acf.instagram_link" class="i-instagram">
              <a :href="member.acf.instagram_link" target="_blank">
                <font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!--end container-->
    <img
      class="section-bg"
      v-lazy="'https://snow.wellslamont.com/wp-content/uploads/2019/01/mountain-bg-web-1.jpg'"
      style="z-index: -1;"
      v-parallax="0.2"
    >
  </section>
  <!-- end -->
</template>
<script>
import { TeamService } from "@/services/WpApi";

export default {
  name: "TeamSection",
  data() {
    return {
      team: []
    };
  },
  mounted() {
    // this.$Lazyload.$on("loaded", function(
    //   {
    //     bindType,
    //     el,
    //     naturalHeight,
    //     naturalWidth,
    //     $parent,
    //     src,
    //     loading,
    //     error
    //   },
    //   formCache
    // ) {
    //   el.offsetParent.className += " loaded";
    // });
    this.getTeam();
  },
  methods: {
    async getTeam() {
      const response = await TeamService.fetchTeam();
      this.team = response.data;
    }
  }
};
</script>

