<template>
  <section
    id="about"
    class="s-about target-section"
    v-lazy:background-image="
      'https://snow.covertnine.com/wp-content/uploads/2021/09/30706_Hydrahyde_page1199W_vignette_3.jpg'
    "
    style="background-repeat:no-repeat; background-position: center;"
  >
    <div class="container">
      <div class="row" data-aos="fade-up">
        <div class="col-7 offset-md-0 col-md-8 col-lg-5 offset-lg-1">
          <div class="about-box">
            <h2 class="h2 display-1">
              Since
              <span class="h1 big-year">1907</span>
            </h2>
            <p class="subheading display-3">
              Backed by legacy <br />Driven by innovation
            </p>
            <p class="pad-right">
              With over 110 years in the work glove business, Wells Lamont has
              taken their tried and true "Stubborn About Quality" legacy to the
              slopes
            </p>
          </div>
          <!--end about-box-->
        </div>
        <!--end col-->
      </div>
      <!-- end row -->
    </div>
    <!--end container-->
  </section>
</template>

<script>
export default {
  name: "AboutSection"
};
</script>
