<template>
  <section id="gloves" class="s-gloves target-section">
    <div class="container">
      <div class="row" data-aos="fade-up">
        <div class="col-md-8 offset-md-2 col-sm-12">
          <div class="gloves-heading text-center">
            <p
              class="subheading display-3 text-center"
            >QUALITY HAND PROTECTION DESIGNED FOR THE SLOPES</p>
            <lazy-image
              :lazy-src="'https://snow.wellslamont.com/wp-content/uploads/2019/01/overlook@2x.png'"
              alt="GLOVES"
              height="237"
              width="730"
              backgroundColor="none"
              class="img-fluid fade-in"
            ></lazy-image>
          </div>
          <!--end gloves-heading-->
        </div>
        <!--end col-->
      </div>
      <!-- end row -->
      <div class="row align-items-center row-gloves" data-aos="fade-up">
        <div v-for="(glove, index) in gloves" :key="index" :class="getGloveClass(index)">
          <!-- product is the value, id is the key in the products object -->
          <div class="glove-box">
            <router-link
              :to="{name:'glove', params: { slug: glove.slug, gloveData: glove }}"
              class="single-glove-link lazy-animate"
            >
              <lazy-image
                :lazy-src="glove.acf.featured_images[0].url"
                :alt="glove.acf.sku"
                height="350"
                width="350"
                backgroundColor="none"
                class="img-fluid fade-in"
              ></lazy-image>
            </router-link>
          </div>
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
</template>
<script>
import { ProductsService } from "@/services/WpApi";

export default {
  name: "GlovesSection",
  data() {
    return {
      gloves: []
    };
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    async getProducts() {
      const response = await ProductsService.fetchProducts();
      this.gloves = response.data;
    },
    getGloveClass: function(index) {
      let colClass = "glove-col text-center";
      let gloves = this.gloves
      if (gloves.length % 3 === 0) {
        colClass += " col-md-4 offset-sm-0 col-sm-4 col-6";
      } else if (gloves.length % 3 === 1) {
        if ((index + 1) === gloves.length) {
          colClass += " col-sm-4 offset-sm-4";
        } else {
          colClass += " col-sm-4 offset-sm-0";
        }
        // if gloves.length % 3 === 2
      } else {
        if (index + 2 === gloves.length) {
          colClass += " col-sm-4 offset-sm-2";
        } else if ((index + 1) === gloves.length) {
          colClass += " col-sm-4 offset-sm-0";
        } else {
          colClass += " col-md-4 col-sm-4 col-6";
        }
      }
      if (gloves.length % 2 === 0) {
        colClass += " col-6 offset-0";
      } else {
        if ((index + 1)=== gloves.length) {
          colClass += " col-6 offset-3";
        } else {
          colClass += " col-6 offset-0";
        }
      }

      return colClass;
    }
  }
};
</script>

