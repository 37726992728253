<template>
  <section
    id="snowvideo"
    class="s-video target-section"
    data-aos="fade-up"
    v-waypoint="{ active: true, callback: triggerAnim, options: {} }"
  >
    <div class="hero-holder s-video-snow-cover">
      <div class="container">
        <div class="s-video-content">
          <div class="punchout snow-team"></div>
          <div class="punchout videos"></div>

          <a
            href="https://www.youtube.com/playlist?list=PLGdESsKO1yKafuOzO7s26UvzIkVSKAxnM"
            target="_blank"
            class="btn btn--primary smoothscroll snow-vimeo"
            >SNOW VIDEO</a
          >
        </div>
      </div>
      <mq-layout :mq="['lg', 'xl']">
        <div class="iframe-wrapper">
          <vimeo-player
            ref="bgplayer"
            :video-id="vimeoBg.videoId"
            @ready="onReady"
            loop="true"
          ></vimeo-player>
        </div>
      </mq-layout>
      <mq-layout :mq="['xs', 'sm', 'md']">
        <div
          class="s-video-fallback"
          v-lazy:background-image="
            'https://snow.wellslamont.com/wp-content/uploads/2019/03/new-section-glove.jpg'
          "
        ></div>
      </mq-layout>
    </div>
    <!--end hero holder-->
    <div class="s-video-vimeo-nav">
      <transition-group
        name="staggered-fade"
        class="nav justify-content-center nav-fill"
        tag="div"
        v-bind:css="false"
        v-on:before-enter="beforeEnter"
        v-on:enter="enter"
      >
        <a
          v-for="(video, index) in videos"
          :key="index"
          :style="{
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '90%',
            backgroundImage: 'url(' + video.snippet.thumbnails.medium.url + ')'
          }"
          v-bind:data-index="index"
          class="nav-item vimeo-link"
          @click="openModal(video)"
        ></a>
      </transition-group>
    </div>
    <!-- <link v-if="preLoading" rel="preload"  as="video" :href="'https://player.vimeo.com/video/' + videoId + '?autoplay=1&app_id=122963'"> -->
    <SimpleModal v-if="showModal" @close="closeModal" class="vimeo-modal">
      <iframe width="560" height="315" :src="'https://www.youtube.com/embed/' + videoId" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </SimpleModal>
    <!--end nav container-->
    <div class="s-video-vimeo-nav-bg"></div>
  </section>
</template>
<script>
import { YoutubeService } from "@/services/WpApi";
import VueFriendlyIframe from "vue-friendly-iframe";
import SimpleModal from "@/components/helpers/SimpleModal";
import { vueVimeoPlayer } from "vue-vimeo-player";
import Velocity from "velocity-animate";

export default {
  name: "VideoSection",
  components: { VueFriendlyIframe, SimpleModal, vueVimeoPlayer },
  data() {
    return {
      videos: [],
      videoId: "",
      vimeoBg: {
        videoId: "309317108",
        playerReady: false
      },
      showModal: false,
      preLoading: false
    };
  },
  mounted() {},
  methods: {
    triggerAnim({ going }) {
      if (going === this.$waypointMap.GOING_IN) {
        this.getVideos();
      }
    },
    closeModal() {
      this.showModal = false;
      // this.$refs.bgplayer.play();
    },
    onReady() {
      this.vimeoBg.playerReady = true;
      this.$refs.bgplayer.play();
      this.$refs.bgplayer.mute();
    },
    openModal: function(video) {
      if (this.$mq === "sm" || this.$mq === "xs") {
        let videoLink = 'http://www.youtube.com/watch?v=' + video.snippet.resourceId.videoId
        window.open(videoLink, "_blank");
      } else {
        this.$refs.bgplayer.pause();
        this.showModal = true;
        this.videoId = video.snippet.resourceId.videoId;
      }
    },
    beforeEnter: function(el) {
      el.style.opacity = 0;
    },
    enter: function(el, done) {
      var delay = el.dataset.index * 140;
      setTimeout(function() {
        Velocity(el, { opacity: 1 }, { complete: done });
      }, delay);
    },
    async getVideos() {
      const response = await YoutubeService.fetchFeed();
      this.videos = response.data.slice(0, 7);
    }
  }
};
</script>
