<template>
  <section id="snow-social" class="s-social" data-aos="fade-up">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-xs-12 col-sm-4">
          <div class="snow-social-header">
            <h2>
              <span class="h2">SNOW</span>
              <span class="h1 big-text">SOCIAL</span>
            </h2>
          </div>
        </div>
        <div class="col-xs-12 col-sm-8 text-right">
          <span class="subheading display-3 h4 i-insta-connect"
            ><a
              href="https://www.instagram.com/wellslamontsnow"
              target="_blank"
              class="connect-link"
              >Connect on Instagram with the Wells Lamont SNOW Team</a
            ></span
          >
        </div>
      </div>
      <!--end row-->
      <div class="row">
        <div
          v-for="(item, index) in feed"
          :key="index"
          class="col-6 col-sm-6 col-md-6 col-lg-3 instagram-feed"
        >
          <a
            :href="item.permalink"
            target="_blank"
            class="instagram-image-link"
          >
            <font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon>
            <div
              class="instagram-image-div lazy-animate"
              v-lazy:background-image="item.media_url"
              :alt="item.caption"
            ></div>
          </a>
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
</template>
<script>
import { InstagramService } from "@/services/WpApi";
export default {
  name: "SocialSection",
  data() {
    return {
      feed: {},
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: 1
      }
    };
  },
  mounted() {
    this.getFeed();
  },
  methods: {
    async getFeed() {
      const response = await InstagramService.fetchFeed();
      this.feed = response.data;
      let instafeed = response.data.filter(object => {
        return object.media_type != "VIDEO";
      });
      this.feed = instafeed.slice(0,4);
    }
  }
};
</script>
