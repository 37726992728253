<template>
  <div class="home-social-icons">
    <ul class="home-content__social">
      <li>
        <a href="https://www.instagram.com/wellslamontsnow/" target="_blank">
          <font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon>
        </a>
      </li>
      <li>
        <a href="https://vimeo.com/channels/wellslamontsnow" target="_blank">
          <font-awesome-icon :icon="['fab', 'vimeo']"></font-awesome-icon>
        </a>
      </li>
    </ul>
  </div>
</template>
<!--end home-social-icons-->
<script>
export default {
  name: "SocialSidebar"
};
</script>