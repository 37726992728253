<template>
  <div class="wrapper">
    <SocialSidebar></SocialSidebar>
    <HeaderMain></HeaderMain>
    <HomeSection></HomeSection>
    <InstagramSection></InstagramSection>
    <VideoSection v-waypoint="{ active: true, callback: highlightMenu, options: {} }"></VideoSection>
    <AboutSection v-waypoint="{ active: true, callback: highlightMenu, options: {} }"></AboutSection>
    <TeamSection v-waypoint="{ active: true, callback: highlightMenu, options: {} }"></TeamSection>
    <HydraHydeSection></HydraHydeSection>
    <GlovesSection v-waypoint="{ active: true, callback: highlightMenu, options: {} }"></GlovesSection>
    <PartnersSection v-waypoint="{ active: true, callback: highlightMenu, options: {} }"></PartnersSection>
    <FooterMain></FooterMain>
  </div>
</template>
<!--end home-social-icons-->
<script>
// @ is an alias to /src
import HomeSection from "@/components/sections/HomeSection.vue";
import AboutSection from "@/components/sections/AboutSection.vue";
import InstagramSection from "@/components/sections/InstagramSection.vue";
import VideoSection from "@/components/sections/VideoSection.vue";
import TeamSection from "@/components/sections/TeamSection.vue";
import HydraHydeSection from "@/components/sections/HydraHydeSection.vue";
import GlovesSection from "@/components/sections/GlovesSection.vue";
import PartnersSection from "@/components/sections/PartnersSection.vue";
import FooterMain from "@/components/layout/FooterMain.vue";
import HeaderMain from "@/components/layout/HeaderMain.vue";
import SocialSidebar from "@/components/widgets/SocialSidebar.vue";

export default {
  name: "HomePage",
  components: {
    HomeSection,
    AboutSection,
    InstagramSection,
    VideoSection,
    TeamSection,
    HydraHydeSection,
    GlovesSection,
    PartnersSection,
    HeaderMain,
    FooterMain,
    SocialSidebar
  },
  data() {
    return {
      fromTop: 0
    };
  },
  created() {
    document.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.hash) {
        const hash = to.hash;
        const id = hash.replace("#", "");
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView();
        }
      }
    });
  },
  methods: {
    highlightMenu({ el, going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        this.$root.$emit("highlight", [el, " current"]);
      }
      if (going == this.$waypointMap.GOING_OUT) {
        this.$root.$emit("highlight", [el, ""]);
      }
    },
    handleScroll() {
      this.fromTop = document.scrollingElement.scrollTop;
      if (this.fromTop <= 500) {
        this.$root.$emit("toggleStuff", false);
      }

      if (this.fromTop > 500) {
        this.$root.$emit("toggleStuff", true);
      }
    }
  }
};
</script>